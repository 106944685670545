<template>
    <metainfo></metainfo>
    <section>
        <header class="w-screen h-screen/2">
            <div class="film w-full h-full flex">
                <div class="mx-auto my-auto">
                    <h1 class="text-6xl md:text-8xl mx-auto my-auto uppercase text-gray-50 font-semibold">Artists</h1>
                </div>
            </div>
        </header>
        <div class="md:grid md:grid-cols-3 md:gap-4 px-2 lg:pl-4 lg:pr-24 3xl:pl-72 3xl:pr-96 py-12 pb-24 space-y-8 md:space-y-0">
            <div>
                <div class="w-full bg-white cursor-pointer hover:shadow-xl shadow-lg rounded-lg">
                    <div class="rounded-lg">
                        <img 
                            src="@/assets/img/baskerben-1.jpg" 
                            alt="Son of A Crime" 
                            class="rounded-t-lg w-full">
                        <div class="flex px-4 justify-between py-4">
                            <h2 class="my-auto font-medium text-lg">BASKERBEN</h2>
                            <div class="flex space-x-4">
                                <a 
                                    class="my-auto"
                                    target="_blank"
                                    href="https://music.apple.com/us/artist/baskerben/475907362">
                                    <img 
                                        src="@/assets/img/itunes-2.svg" 
                                        alt="Baskerben - Apple Music"
                                        class="w-10 xl:w-8">
                                </a>
                                <a 
                                    class="my-auto"
                                    target="_blank"
                                    href="https://www.youtube.com/c/baskerben">
                                    <img 
                                        src="@/assets/img/youtube.svg" 
                                        alt="Baskerben - Youtube"
                                        class="w-10 xl:w-8 object-fit">
                                </a>
                                <a 
                                    class="my-auto"
                                    target="_blank"
                                    href="https://open.spotify.com/artist/48HIkNRgSEv5rM4hjBphyJ">
                                    <img 
                                        src="@/assets/img/spotify.svg" 
                                        alt="Baskerben - Spotify"
                                        class="w-10 xl:w-8 object-fit">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Artists',
    metaInfo() {
        return {
            title: 'Artists | PlatinumTags Media',
        }
    },

    data(){
        return {
        }
    }
}
</script>

<style scoped>
    header{
        background: url('../assets/img/studio-mic.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
    .soc{
        background: url('../assets/img/soc.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    header>.film{
        background: rgb(0, 0, 0, 0.7);
    }
</style>